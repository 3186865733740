:root {
	--white: #ffffff;
	--light-grey: #E5E5E5;
	--grey: #DADADA;
	--grey2: #888888;
	--orange: #FF8400;
	--orange2: #EC7B01;
	--orange3: #FF9D33;
	--orange4: #E57700;
	--blue: #004399;
	--blue2: #1252A6;
	--grey3: #333333;
	--cream: #FFE3C5;
	--light-blue: #75A6E5;
	--light-blue2: #EBF4FF;
	--light-blue3: #F5FDFF;
	--green: #018F20;
	--red: #FF0000;
	--blue3: #EBF4FF;
	--dgrey: #222222;
	--blue3: #3B74BE;
}
