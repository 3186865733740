/* You can add global styles to this file, and also import other style files */

@import "assets/styles/base/common";
@import "assets/styles/base/reset";
@import "assets/styles/base/typography";
@import "assets/styles/base/variables";
@import "assets/styles/base/utility";

// .toast-error {
//   background-color: white;
//   color: black;
//   border: 1px solid #75A6E5;
//   box-shadow: none;
// }

// .toast-container .ngx-toastr{
//   color: black;
// }
body {
  background: rgb(249 251 255);
}
