/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";

/* Generated theme by Material Theme Generator */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "~@angular/material/theming";

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons+Sharp";

html, body { height: 100%; }
body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    min-height: 100vh;
}
p {
    font: 600 20px/1.2 'Nunito', sans-serif !important;
    color: inherit;
}
b {
    font: 800 44px/1.2 'Nunito', sans-serif;
    color: inherit;
}
.justify-end {
    justify-content: end;
}
.form {
    input {
        display: block;
        height: 50px;
        width: 300px;
        border: none;
        padding: 0px 15px 0px 23px;
        font: 400 16px/1.3em 'Nunito', sans-serif;
        color: #292d34;
        border-radius: 5px;
        margin-right: 13px;
    }
}
button {
    transition: .2s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    // width: 100%;
    min-width: 99px;
    text-align: center;
    color: #fff;
    // background: #7b68ee;
    // box-shadow: 0 5.92741px 14.8185px rgb(123 104 238 / 50%);
    border: none;
    cursor: pointer;
    padding: 2px 0 0 0;
    border-radius: 6px;
}
.add_pointer{
    cursor: pointer;
}
.mat-paginator {
    background-color: transparent;
    color: black;
  }
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0!important;
}
.btn-primary {
    background-color: var(--orange);
    border-color: var(--orange);
    &:hover {
        background-color: var(--orange2);
        border-color: var(--orange2);
    }
}

.mat-icon {
    width: 22px !important;
    height: 22px !important;
}
.material-icons {
    font-size: 20px !important;
}
.mat-badge-content {
    color: #111111 !important;
    background: var(--white) !important;
}
.d-flex {
    display: flex;
}
.align-item {
    align-items: center
}
.align-end {
    align-items: end;
}
.display-grid {
    display: inline-grid;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-end {
    text-align: end;
}
.justify-end {
    justify-content: end;
}
.justify-center {
    justify-content: center;
}
.mat-form-field-infix {
    border-top: inherit;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--grey);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #3960B5 !important;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    //padding: 15px 1em !important;
}
.mat-form-field-subscript-wrapper {
    top: calc(100% - 0em);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -5px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
    top: -4px;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.8em 0 1em 0;
}
.bRight {
    border-right: 1px solid var(--white);
    height: 20px;
    width: 5px;
}
.pro_icon {
    width: 32px;
    height: 32px;
    img {
        border-radius: 15px;
    }
}
.pdStyle {
    padding: 0 3%;
    height: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
    height: 32px;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
    height: 100%;
}

.action {
    .mat-icon-button {
        width: 97px !important;
        height: 32px !important;
        border-radius: 5px !important;
        color: var(--white);
        line-height: 0px;
    }
    button {
        box-shadow: none;
        background-color: var(--orange);
        padding: 0 10px;
        &:active {
            transform: rotate(0deg);
        }
    }
    span {
        img {
            transform: rotate(180deg);
            margin: 0px 10px 0 15px;
        }
    }
}
.action button {
    &:active, &:focus {
        span {
            img {
                transform: rotate(0deg);
            }
        }
    }
}
.mat-menu-content:not(:empty) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.mat-menu-panel {
    background: var(--white) !important;
}
.mat-menu-item {
    font-family: 'Nunito', sans-serif !important;
    color: #333333;
    line-height: 0 !important;
    height: 40px !important;
}
button.mat-menu-item {
    box-shadow: none !important;
}
.mat-divider {
    border-top-color: var(--grey);
}
.mat-tab-label, .mat-tab-link, .mat-form-field, .mat-select, .mat-calendar {
    font-family: 'Nunito', sans-serif !important;
}

.mat-tab-label {
    text-align: left !important;
    justify-content: left !important;
    .mat-tab-label-content {
        font-weight: 400;
       font-size: 16px;
    }
}

.mat-tab-body-content {
    overflow: inherit !important;
}

.btn-outline-primary {
    color: var(--grey3);
    border-color: var(--orange);
}
.btn {
    border: 2px solid var(--orange);
}
.cursor-pointer {
    cursor: pointer;
}
.btn-outline-primary:hover {
    background-color: var(--orange3) !important;
    border-color: var(--orange3) !important;
}
.btn-outline-primary:active, .btn-outline-primary:visited {
    background-color: var(--orange4) !important;
    border-color: var(--orange4) !important;
}
.mat-tab-group {
    max-height: 550px !important;
}
table {
    border: 1px solid var(--grey) !important;
    tr.mat-header-row, tr.mat-row {
        height: 32px;
    }
    td.mat-cell {
        border-bottom-color: var(--grey);
    }
    .mat-header-cell {
        color: var(--grey2);
        background-color: var(--light-blue2);
        font-size: 14px;
        font-weight: 400;
    }
    .mat-cell {
        color: var(--grey3);
        font-size: 14px;
        font-weight: 400;
    }
    th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
        padding-top: 5px;
    }
}
.popup-title {
    background-color: var(--light-blue3);
}
.icon-style {
    position: absolute;
    right: 2%;
    color: var(--blue2);
}
.mat-dialog-content {
    padding: 15px 20px !important;
    margin: inherit !important;
}
.mat-dialog-title {
    .material-icons {
        font-size: 25px !important;
    }
}
.paginator_buttons{
    width: 20px;
    height: 20px;
    color: #888888;
    border: 1px solid #888888;
    background: #FFFFFF;
    min-width:20px !important;
    margin: 0px 8px 0px 8px;
    border-radius: 10px;
  }
  .paginator_arrows{
        .mat-paginator-icon{
            fill: #888 !important;
    }
  }
  .paginator_arrows_disabled{
    .mat-paginator-icon{
        fill: #888 !important;
        opacity: 0.5;
}
}
.custom_outline{
    border: 1px solid #DADADA;
    border-radius: 4px;
    padding-left: 6px;
}
.mat-input-element::placeholder{
    color: #888888;
 }
//  ::ng-deep .mat-select-arrow {
// 	background: url(../../images/icons/select.png) !important;
// 	width: 12px !important;
// 	height: 7px !important;
// 	display: block !important;
// }

.shadow {
    box-shadow: 0 3.92741px 2.8185px #ccc !important;
}
::ng-deep .mat-elevation-z8 {
    box-shadow: 0px 2px 16px rgba(34, 34, 34, 0.15) !important;
}
.mat-datepicker-content .mat-calendar-previous-button{
  color:black
}
.mat-datepicker-content .mat-calendar-next-button {
  color: black;
}
.mat-calendar-arrow {
  fill:black;
}
::ng-deep {
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: transparent !important;
    }
   
    .mat-table-sticky {
        position: sticky !important;
    }
  
}
.greenColor {
    color: #018F20 !important;
}
.redColor {
    color: #D30404 !important;
}
@media screen and (max-width: 768px) {
    .mat-tab-body-content {
        padding-right: 10px;
        padding-bottom: 20px;
    }
    ::ng-deep {
        .mat-dialog-content {
            max-height: 50vh !important;
        }
    }
}