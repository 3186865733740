/* Reset Css */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  font-family: "Nunito", sans-serif;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  font-family: "Nunito", sans-serif;
}
html {
  height: 100%;
  font-family: "Nunito", sans-serif;
}
body {
  line-height: 1;
  height: 100%;
  font-family: "Nunito", sans-serif;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}
.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px/20px "Nunito", sans-serif;
}
.mat-select-value,
.mat-option.mat-active,
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-dialog-container,
.mat-input-element,
.mat-expansion-panel-header-title {
  color: var(--grey3) !important;
}
.mat-select-placeholder,
.mat-option {
  color: var(--grey2);
}
.mat-select-panel {
  background: var(--white);
}
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgb(243 239 239);
  color: #333333;
}
.mat-select-arrow {
  color: var(--grey2);
}
// .cdk-overlay-pane {
//     width: 40% !important;
// }
.mat-dialog-container {
  background: var(--white);
  color: var(--blue);
  padding: 0 !important;
  overflow: hidden !important;
}
.mat-dialog-title {
  margin: 0 !important;
  padding: 15px !important;
}
.mat-calendar-body-cell-content {
  width: 36% !important;
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator,
.mat-calendar-table-header,
.mat-calendar-body-label,
.mat-calendar-controls .mat-button,
.mat-calendar-arrow {
  color: #111;
}
.mat-calendar-body-selected {
  background-color: var(--orange);
  color: var(--white);
}
.mat-input-element {
  caret-color: var(--grey2);
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--light-blue);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--blue);
}
.mat-dialog-actions {
  min-height: inherit !important;
  margin-bottom: inherit !important;
  padding: 8px 15px !important;
}
