/*color*/
.light-grey-bg {
    background-color: var(--light-grey);
}
.white-bg, .mat-datepicker-content, .mat-table {
    background-color: var(--white);
}
.blue-bg {
    background-color: var(--blue);
}
.blueBg {
    background-color: var(--blue3);
}
.grey-color {
    color: var(--grey2);
}
.dgrey {
    color: var(--dgrey);
}
.grey3 {
    color: var(--grey3);
}
.orange-color {
    color: var(--orange);
}
.green-color {
    color: var(--green);
}
.white {
    color: var(--white);
}
.blue {
    color: var(--blue);
}
.blue2 {
    color: var(--blue2);
}
.error {
    color: var(--red);
}
.btext { 
    color: var(--blue3);
}
/*margin*/
.mt-8 {
    margin-top: 8px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-25 {
    margin-top: 25px !important;
}
.mt-30 {
    margin-top: 30px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-100 {
    margin-top: 100px;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-20 {
    margin-left: 20px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-30 {
    margin-right: 30px;
}
.m20 {
    margin: 0 20px !important;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-90 {
    margin-bottom: 90px;
}
.pt-10 {
    padding-top: 10px;
}
.pl-10 {
    padding-left: 10px;
}
.pl-0{
    padding-left: 0!important;
}

/*width*/
